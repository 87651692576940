<script setup lang="ts">
import { useStandNaviSetup } from '~/composables/useStandNaviSetup'

const { StandNavi } = useStandNaviSetup()
</script>

<template>
  <div class="col-span-full">
    <StandNavi />
  </div>
</template>
