import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
export const GqlSdks = {
  default: defaultGqlSdk,
}
export const GqClientOps = {"default":["CreateSegment","DeleteSegment","GetBusinessEntity","GetBusinessEntityAssignedServiceTypes","GetBusinessEntityOrganizations","GetBusinessEntityReserveSubscriptionPlans","GetBusinessEntitySegments","GetBusinessEntityCustomerLoyaltyRanks","GetBusinessEntityTicketBookSources","GetCustomer","GetCustomerActivities","GetCustomers","GetCustomersViewSetting","GetFeatures","GetMe","GetSegment","GetSegmentCustomers","MarkAsStarted","UpdateCustomersViewSetting","UpdateSegment","UpdateSegmentsSortOrder"]}
export const GqlCreateSegment = (...params) => useGql()('CreateSegment', ...params)
export const GqlDeleteSegment = (...params) => useGql()('DeleteSegment', ...params)
export const GqlGetBusinessEntity = (...params) => useGql()('GetBusinessEntity', ...params)
export const GqlGetBusinessEntityAssignedServiceTypes = (...params) => useGql()('GetBusinessEntityAssignedServiceTypes', ...params)
export const GqlGetBusinessEntityCustomerLoyaltyRanks = (...params) => useGql()('GetBusinessEntityCustomerLoyaltyRanks', ...params)
export const GqlGetBusinessEntityOrganizations = (...params) => useGql()('GetBusinessEntityOrganizations', ...params)
export const GqlGetBusinessEntityReserveSubscriptionPlans = (...params) => useGql()('GetBusinessEntityReserveSubscriptionPlans', ...params)
export const GqlGetBusinessEntitySegments = (...params) => useGql()('GetBusinessEntitySegments', ...params)
export const GqlGetBusinessEntityTicketBookSources = (...params) => useGql()('GetBusinessEntityTicketBookSources', ...params)
export const GqlGetCustomer = (...params) => useGql()('GetCustomer', ...params)
export const GqlGetCustomerActivities = (...params) => useGql()('GetCustomerActivities', ...params)
export const GqlGetCustomers = (...params) => useGql()('GetCustomers', ...params)
export const GqlGetCustomersViewSetting = (...params) => useGql()('GetCustomersViewSetting', ...params)
export const GqlGetFeatures = (...params) => useGql()('GetFeatures', ...params)
export const GqlGetMe = (...params) => useGql()('GetMe', ...params)
export const GqlGetSegment = (...params) => useGql()('GetSegment', ...params)
export const GqlGetSegmentCustomers = (...params) => useGql()('GetSegmentCustomers', ...params)
export const GqlMarkAsStarted = (...params) => useGql()('MarkAsStarted', ...params)
export const GqlUpdateCustomersViewSetting = (...params) => useGql()('UpdateCustomersViewSetting', ...params)
export const GqlUpdateSegment = (...params) => useGql()('UpdateSegment', ...params)
export const GqlUpdateSegmentsSortOrder = (...params) => useGql()('UpdateSegmentsSortOrder', ...params)