import revive_payload_client_xUD0t90Q3h from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.2.4_eslint@9.23.0_jiti@2.4_92419f50053bdebeec68905ffb49717a/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_p5T0WFgy21 from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.2.4_eslint@9.23.0_jiti@2.4_92419f50053bdebeec68905ffb49717a/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_6sWbVNd9bw from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.2.4_eslint@9.23.0_jiti@2.4_92419f50053bdebeec68905ffb49717a/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_1QMofFdAus from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.2.4_eslint@9.23.0_jiti@2.4_92419f50053bdebeec68905ffb49717a/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_2UcIpHUBgb from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.2.4_eslint@9.23.0_jiti@2.4_92419f50053bdebeec68905ffb49717a/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_IzyXWTAaJm from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.2.4_eslint@9.23.0_jiti@2.4_92419f50053bdebeec68905ffb49717a/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_TgBTyEQZbg from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.2.4_eslint@9.23.0_jiti@2.4_92419f50053bdebeec68905ffb49717a/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zbacBAXhj0 from "/home/runner/work/cdp-dashboard/cdp-dashboard/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/cdp-dashboard/cdp-dashboard/.nuxt/components.plugin.mjs";
import prefetch_client_bsuJs7VBXT from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.2.4_eslint@9.23.0_jiti@2.4_92419f50053bdebeec68905ffb49717a/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_zUnAuy24wZ from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt-graphql-client@0.2.43_@parcel+watcher@2.5.1_@types+node@22.13.14_graphql-tag@2.12._c098182199a26120e49703422936b7cf/node_modules/nuxt-graphql-client/dist/runtime/plugin.js";
import sentry_client_shVUlIjFLk from "/home/runner/work/cdp-dashboard/cdp-dashboard/plugins/sentry.client.ts";
import set_graphql_host_client_hEFjBexpur from "/home/runner/work/cdp-dashboard/cdp-dashboard/plugins/set-graphql-host.client.ts";
import toasts_client_unuLMHjfa9 from "/home/runner/work/cdp-dashboard/cdp-dashboard/plugins/toasts.client.ts";
export default [
  revive_payload_client_xUD0t90Q3h,
  unhead_p5T0WFgy21,
  router_6sWbVNd9bw,
  payload_client_1QMofFdAus,
  navigation_repaint_client_2UcIpHUBgb,
  check_outdated_build_client_IzyXWTAaJm,
  chunk_reload_client_TgBTyEQZbg,
  plugin_zbacBAXhj0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_bsuJs7VBXT,
  plugin_zUnAuy24wZ,
  sentry_client_shVUlIjFLk,
  set_graphql_host_client_hEFjBexpur,
  toasts_client_unuLMHjfa9
]