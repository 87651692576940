import { useStandNavi } from '@stpkg/stand-vue'

import { useRoute, useRouter } from '#imports'

export const useStandNaviSetup = () => {
  const relatedPathMap = [
    {
      appId: 'cdp_dashboard',
      path: '/',
      relatedPaths: ['/*'],
    },
  ]

  const route = useRoute()
  const businessEntityUuid = route.params.business_entity_uuid as string

  const router = useRouter()
  const getDashboardMetaUrl = router.resolve({ path: '/api/dashboard_meta', query: { businessEntityId: businessEntityUuid } }).href
  const putNavigationUrl = router.resolve({ path: '/api/navigation', query: { businessEntityId: businessEntityUuid } }).href

  const { component: StandNavi } = useStandNavi({
    getDashboardMetaUrl,
    putNavigationUrl,
    currentPath: route.path,
    relatedPathMap,
    currentService: 'cdp',
  })

  return { StandNavi }
}
